import React from 'react'
import { graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlogEntryCard from '../components/blog-entry-card'
import style from './index.module.scss'

export const pageQuery = graphql`
  query BlogIndex($draftInFilter: [Boolean]!) {
    site {
      siteMetadata {
        title
        keywords
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
      filter: {
        frontmatter: {
          lang: {
            eq: "en"
          }
          draft: {
            in: $draftInFilter
          }
        }
      }
    ) {
      edges {
        node {
          timeToRead
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            keywords
            draft
          }
        }
      }
    }
  }
`

class BlogIndex extends React.Component {

  render() {
    const { data } = this.props
    const {title: siteTitle, keywords} = data.site.siteMetadata
    const posts = data.allMarkdownRemark.edges

    const totalKeybwords = posts
      .map(p => p.node.frontmatter.keywords || [])
      .concat([keywords])
      .reduce((out, keywords) => {
        out.push(...keywords)
        return out
      }, [])

    return (
      <Layout location={this.props.location} title={siteTitle} showMenu>
        <SEO title="Index" keywords={new Set(totalKeybwords)}/>
        <Bio big className={style.bio}/>
        <div className={style.entriesList}>
          {posts.map(({ node }) => (<BlogEntryCard key={node.fields.slug} node={node} />))}
        </div>
      </Layout>
    )
  }
}

export default BlogIndex